.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  background: #20212e;
  border-radius: 24px;
  padding: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;

  color: #ecddff;
  padding-bottom: 8px;
}
.countDownContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #30303e;
  border-radius: 18px;
  padding: 15px 18px;
}

.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: rgb(236, 221, 255, 0.8);
}
.lotteryOver {
  font-size: 18px;
  text-align: center;
  border-top: 1px solid rgb(236, 221, 255, 0.2);
  padding-top: 10px;
}
.infoButtons {
  display: flex;
  gap: 7px;
  align-items: center;
  flex-wrap: wrap;
}
.publicRound {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 15px;
  background: #ecddff;
  border-radius: 130px;
}
.publicRoundText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 167%;
  text-align: right;
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.circle {
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
}
.liveButton {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 167%;
  color: #ecddff;
  background: linear-gradient(114.79deg, #c040f1 -17.76%, #4b3cff 9.88%);
  border-radius: 130px;
  border: none;
  outline: none;
  padding: 8px 15px;
}
.liveButton:hover {
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
  cursor: pointer;
}
.contactAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: #30303e;
  border-radius: 18px;
  padding: 25px 18px;
}
.address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.copy {
  cursor: pointer;
}
.buyContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  background: #30303e;
  border-radius: 18px;
  padding: 25px 18px;
}
.buyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.priceWrapper {
  display: flex;
  gap: 5px;
}
.bnbContainer {
  width: 36px;
  background: rgba(236, 221, 255, 0.1);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bnb {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  /* identical to box height */

  color: #ecddff;
}
.usd {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  color: rgb(236, 221, 255, 0.6);
}
.quantityWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 12px;
  background: rgba(236, 221, 255, 0.1);
  border-radius: 14px;
  cursor: pointer;
}
.quantity {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.06em;

  color: #ecddff;

  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 156px;
  padding: 20px 10px;
  background: rgba(236, 221, 255, 0.1);
  border-radius: 14px;
}
.input {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.06em;

  color: #ecddff;

  width: 100%;
  background: none;
  border: none;
  outline: none;
  height: 100%;
  text-align: center;
}
.icon {
  font-size: 28px;
  color: #ecddff;
}
.button {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 133%;
  text-transform: capitalize;

  color: #ecddff;
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
  border-radius: 14px;
  width: 100%;
  border: none;
  outline: none;
  padding: 15px 18px;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
  opacity: 0.8;
}
@media only screen and (max-width: 1199px) {
  .header {
    flex-direction: column-reverse;
    gap: 25px;
  }
  .iconContainer,
  .quantity {
    padding: 10px;
    height: 35px;
  }
  .quantityWrapper {
    gap: 6px;
  }
  .quantity {
    width: 100px;
  }
  .bnb {
    font-size: 16px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 15px;
  }
  .title {
    font-size: 22px;
    padding-bottom: 5px;
  }
  .publicRound {
    gap: 4px;
    padding: 4px 10px;
  }
  .liveButton {
    padding: 4px 10px;
  }
  .text {
    font-size: 12px;
  }
  .bnbContainer {
    width: 30px;
  }
  .usd {
    font-size: 12px;
  }
  .quantity {
    font-size: 14px;
    width: 70px;
  }
  .input {
    font-size: 14px;
  }
  .iconContainer {
    padding: 8px;
  }
  .icon {
    font-size: 22px;
  }
  .countDownContainer,
  .contactAddress,
  .buyContainer {
    padding: 15px 13px;
  }
  .button {
    padding: 10px 18px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .wrapper {
    padding: 12px 5px;
    padding-bottom: 5px;
  }
  .title {
    padding-left: 8px;
  }

  .publicRound {
    gap: 4px;
    font-size: 11px;
    padding: 2px 8px;
  }
  .liveButton {
    padding: 2px 8px;
  }
  .text {
    font-size: 12px;
  }
  .bnbContainer {
    width: 24px;
  }
  .bnb {
    font-size: 14px;
  }

  .quantity {
    font-size: 12px;
    width: 50px;
  }
  .input {
    font-size: 12px;
  }
  .iconContainer {
    padding: 5px;
  }
  .icon {
    font-size: 20px;
  }
  .countDownContainer,
  .contactAddress,
  .buyContainer {
    padding: 13px 10px;
  }
  .button {
    padding: 8px 18px;
    font-size: 14px;
  }
  .text {
    font-size: 10px;
  }
}
