.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  background: #20212e;
  border-radius: 24px;
  padding: 20px;
  width: 100%;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;

  color: #ecddff;

  border-bottom: 1px solid #30303e;
  padding-top: 8px;
  padding-bottom: 20px;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  font-feature-settings: "liga" off, "kern" off;
  color: rgba(236, 221, 255, 0.5);
  padding: 5px 0;
}
.tagline {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 133%;
  color: rgba(236, 221, 255, 0.8);
}
.textBox {
  border-radius: 18px;
  background: url(../../../images/quote-down.svg), #30303e;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) bottom;
  padding: 18px;
}
.boldText {
  color: rgba(236, 221, 255, 1);
  font-weight: 600;
}
.listBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  max-height: 105px;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(32, 33, 46, 0) 1.3%, #20212e 100%);
  position: relative;
  padding-right: 2px;
}
.listBox::before {
  content: "";
  background: linear-gradient(180deg, rgba(32, 33, 46, 0) 1.3%, #20212e 100%);

  position: absolute;
  width: 100%;
  height: 100%;
}
.listBoxFull {
  overflow: auto;
}
.listBoxFull::before {
  display: none;
}
.listBoxText {
  flex-basis: calc(50% - 8px); /* subtract margin */
  font-size: 14px;
  padding: 0;
}
.buttonContainer {
  display: flex;
  justify-content: center;
}
.button {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 133%;
  text-transform: capitalize;

  color: #ecddff;
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
  border-radius: 14px;
  width: 100%;
  border: none;
  outline: none;
  padding: 15px 18px;
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  .textBox {
    padding: 12px 18px;
  }
}
@media only screen and (max-width: 575px) {
  .listBox {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
  .listBoxText {
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .button {
    padding: 10px 18px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 520px) {
  .button {
    padding: 8px 18px;
    font-size: 14px;
  }
}
